<template>
  <v-main>
    <v-row gutters="2" class="pt-0 pb-0">
      <v-col cols="12" md="12" class="pt-0 pb-0">
        <v-card>
          <v-card-text>
            <v-row no-gutters justify="space-around">
              <v-col cols="12" md="12">
                <!-- Tab with tables visibles -->
                <v-tabs>
                  <v-tab>
                    {{ $t("SUPERADMIN.PENDING") }}
                  </v-tab>
                  <v-tab>
                    {{ $t("SUPERADMIN.ACTIVE") }}
                  </v-tab>
                  <v-tab>
                    {{ $t("SUPERADMIN.DISABLED") }}
                  </v-tab>
                  <v-tab-item>
                    <TableCompany :status="2" />
                  </v-tab-item>
                  <v-tab-item>
                    <TableCompany :status="1" />
                  </v-tab-item>
                  <v-tab-item>
                    <TableCompany :status="0" />
                  </v-tab-item>
                </v-tabs>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TableCompany from "@/view/pages/superadmin/company/components/TableCompany";

export default {
  name: "list-companies",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("SUPERADMIN.TITLE_COMPANY") },
    ]);
  },
  components: { TableCompany },
};
</script>
