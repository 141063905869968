<template>
  <div>
    <Validator></Validator>
    <v-tabs grow v-model="tab" @change="validateTabWithError()">
      <v-tab :class="errorCourse && 'font-error'">
        <span>{{ $t("SUPERADMIN.COURSE") }}</span>
      </v-tab>
      <v-tab :class="errorAccommodation && 'font-error'">
        <span>{{ $t("SUPERADMIN.ACCOMMODATION") }}</span>
      </v-tab>
      <v-tab :class="errorService && 'font-error'">
        <span>{{ $t("SUPERADMIN.SERVICE") }}</span>
      </v-tab>
      <v-tab :class="errorFee && 'font-error'">
        <span>{{ $t("SUPERADMIN.FEE") }}</span>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" class="pt-2">
      <v-tab-item eager>
        <validation-observer ref="observerCourse">
          <v-row>
            <v-col cols="12" xl="6" lg="6" md="6" sm="12">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('SUPERADMIN.COMMISSION_LABELS.COURSE_BUSINESS')"
                rules="max_value_percent:100|required"
              >
                <v-text-field
                  v-mask="'###.##'"
                  suffix="%"
                  :error-messages="errors"
                  v-model="form.CourseBusiness"
                  class="pb-2"
                  dense
                  outlined
                  hide-details="auto"
                  :label="$t('SUPERADMIN.COMMISSION_LABELS.COURSE_BUSINESS')"
                ></v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                :name="
                  $t('SUPERADMIN.COMMISSION_LABELS.COURSE_EXAM_PREPARATION')
                "
                rules="max_value_percent:100|required"
              >
                <v-text-field
                  v-mask="'###.##'"
                  suffix="%"
                  :error-messages="errors"
                  v-model="form.CourseExamPreparation"
                  class="pb-2"
                  dense
                  outlined
                  hide-details="auto"
                  :label="
                    $t('SUPERADMIN.COMMISSION_LABELS.COURSE_EXAM_PREPARATION')
                  "
                ></v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                :name="
                  $t('SUPERADMIN.COMMISSION_LABELS.COURSE_FAMILY_PROGRAMS')
                "
                rules="max_value_percent:100|required"
              >
                <v-text-field
                  v-mask="'###.##'"
                  suffix="%"
                  :error-messages="errors"
                  v-model="form.CourseGeneral"
                  class="pb-2"
                  dense
                  outlined
                  hide-details="auto"
                  :label="
                    $t('SUPERADMIN.COMMISSION_LABELS.COURSE_FAMILY_PROGRAMS')
                  "
                ></v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                :name="$t('SUPERADMIN.COMMISSION_LABELS.COURSE_GAP_YEAR')"
                rules="max_value_percent:100|required"
              >
                <v-text-field
                  v-mask="'###.##'"
                  suffix="%"
                  :error-messages="errors"
                  v-model="form.CourseSeniorPrograms"
                  class="pb-2"
                  dense
                  outlined
                  hide-details="auto"
                  :label="$t('SUPERADMIN.COMMISSION_LABELS.COURSE_GAP_YEAR')"
                ></v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                :name="$t('SUPERADMIN.COMMISSION_LABELS.COURSE_GENERAL')"
                rules="max_value_percent:100|required"
              >
                <v-text-field
                  v-mask="'###.##'"
                  suffix="%"
                  :error-messages="errors"
                  v-model="form.CourseTeacherTraining"
                  class="pb-2"
                  dense
                  outlined
                  hide-details="auto"
                  :label="$t('SUPERADMIN.COMMISSION_LABELS.COURSE_GENERAL')"
                ></v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                :name="
                  $t(
                    'SUPERADMIN.COMMISSION_LABELS.COURSE_LANGUAGE_PLUS_ACTIVITIES_PACKAGE'
                  )
                "
                rules="max_value_percent:100|required"
              >
                <v-text-field
                  v-mask="'###.##'"
                  suffix="%"
                  :error-messages="errors"
                  v-model="form.CourseWorkAndStudy"
                  class="pb-2"
                  dense
                  outlined
                  hide-details="auto"
                  :label="
                    $t(
                      'SUPERADMIN.COMMISSION_LABELS.COURSE_LANGUAGE_PLUS_ACTIVITIES_PACKAGE'
                    )
                  "
                ></v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                :name="$t('SUPERADMIN.COMMISSION_LABELS.COURSE_MINI_GROUPS')"
                rules="max_value_percent:100|required"
              >
                <v-text-field
                  v-mask="'###.##'"
                  suffix="%"
                  :error-messages="errors"
                  v-model="form.CourseSpecializedLanguage"
                  class="pb-2"
                  dense
                  outlined
                  hide-details="auto"
                  :label="$t('SUPERADMIN.COMMISSION_LABELS.COURSE_MINI_GROUPS')"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="6" sm="12">
              <validation-provider
                v-slot="{ errors }"
                :name="
                  $t('SUPERADMIN.COMMISSION_LABELS.COURSE_HOME_TUITION_JUNIORS')
                "
                rules="max_value_percent:100|required"
              >
                <v-text-field
                  v-mask="'###.##'"
                  suffix="%"
                  :error-messages="errors"
                  v-model="form.CourseHomeTuitionJuniors"
                  class="pb-2"
                  dense
                  outlined
                  hide-details="auto"
                  :label="
                    $t(
                      'SUPERADMIN.COMMISSION_LABELS.COURSE_HOME_TUITION_JUNIORS'
                    )
                  "
                ></v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                :name="$t('SUPERADMIN.COMMISSION_LABELS.COURSE_PATHWAY')"
                rules="max_value_percent:100|required"
              >
                <v-text-field
                  v-mask="'###.##'"
                  suffix="%"
                  :error-messages="errors"
                  v-model="form.CourseJuniors"
                  class="pb-2"
                  dense
                  outlined
                  hide-details="auto"
                  :label="$t('SUPERADMIN.COMMISSION_LABELS.COURSE_PATHWAY')"
                ></v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                :name="
                  $t('SUPERADMIN.COMMISSION_LABELS.COURSE_PRIVATE_LESSONS')
                "
                rules="max_value_percent:100|required"
              >
                <v-text-field
                  v-mask="'###.##'"
                  suffix="%"
                  :error-messages="errors"
                  v-model="form.CourseUniversityPreparation"
                  class="pb-2"
                  dense
                  outlined
                  hide-details="auto"
                  :label="
                    $t('SUPERADMIN.COMMISSION_LABELS.COURSE_PRIVATE_LESSONS')
                  "
                ></v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                :name="$t('SUPERADMIN.COMMISSION_LABELS.COURSE_PROFESSIONAL')"
                rules="max_value_percent:100|required"
              >
                <v-text-field
                  v-mask="'###.##'"
                  suffix="%"
                  :error-messages="errors"
                  v-model="form.CourseOneToOne"
                  class="pb-2"
                  dense
                  outlined
                  hide-details="auto"
                  :label="
                    $t('SUPERADMIN.COMMISSION_LABELS.COURSE_PROFESSIONAL')
                  "
                ></v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                :name="
                  $t('SUPERADMIN.COMMISSION_LABELS.COURSE_SENIOR_PROGRAMS')
                "
                rules="max_value_percent:100|required"
              >
                <v-text-field
                  v-mask="'###.##'"
                  suffix="%"
                  :error-messages="errors"
                  v-model="form.CourseWithActivities"
                  class="pb-2"
                  dense
                  outlined
                  hide-details="auto"
                  :label="
                    $t('SUPERADMIN.COMMISSION_LABELS.COURSE_SENIOR_PROGRAMS')
                  "
                ></v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                :name="
                  $t('SUPERADMIN.COMMISSION_LABELS.COURSE_TEACHER_TRAINING')
                "
                rules="max_value_percent:100|required"
              >
                <v-text-field
                  v-mask="'###.##'"
                  suffix="%"
                  :error-messages="errors"
                  v-model="form.CourseHomeTuition"
                  class="pb-2"
                  dense
                  outlined
                  hide-details="auto"
                  :label="
                    $t('SUPERADMIN.COMMISSION_LABELS.COURSE_TEACHER_TRAINING')
                  "
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
        </validation-observer>
      </v-tab-item>
      <v-tab-item eager>
        <validation-observer ref="observerAccommodation">
          <v-row>
            <v-col cols="12" xl="6" lg="6" md="6" sm="12">
              <validation-provider
                v-slot="{ errors }"
                :name="
                  $t('SUPERADMIN.COMMISSION_LABELS.ACCOMMODATION_HOMESTAY')
                "
                rules="max_value_percent:100|required"
              >
                <v-text-field
                  v-mask="'###.##'"
                  suffix="%"
                  :error-messages="errors"
                  class="pb-2"
                  dense
                  outlined
                  hide-details="auto"
                  v-model="form.AccommodationHomestay"
                  :label="
                    $t('SUPERADMIN.COMMISSION_LABELS.ACCOMMODATION_HOMESTAY')
                  "
                ></v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                :name="
                  $t('SUPERADMIN.COMMISSION_LABELS.ACCOMMODATION_RESIDENCE')
                "
                rules="max_value_percent:100|required"
              >
                <v-text-field
                  v-mask="'###.##'"
                  suffix="%"
                  :error-messages="errors"
                  class="pb-2"
                  dense
                  outlined
                  hide-details="auto"
                  v-model="form.AccommodationResidence"
                  :label="
                    $t('SUPERADMIN.COMMISSION_LABELS.ACCOMMODATION_RESIDENCE')
                  "
                ></v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                :name="
                  $t(
                    'SUPERADMIN.COMMISSION_LABELS.ACCOMMODATION_FULL_APARTMENT'
                  )
                "
                rules="max_value_percent:100|required"
              >
                <v-text-field
                  v-mask="'###.##'"
                  suffix="%"
                  :error-messages="errors"
                  class="pb-2"
                  dense
                  outlined
                  hide-details="auto"
                  v-model="form.AccommodationFullApartment"
                  :label="
                    $t(
                      'SUPERADMIN.COMMISSION_LABELS.ACCOMMODATION_FULL_APARTMENT'
                    )
                  "
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="6" sm="12">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('SUPERADMIN.COMMISSION_LABELS.ACCOMMODATION_HOTEL')"
                rules="max_value_percent:100|required"
              >
                <v-text-field
                  v-mask="'###.##'"
                  suffix="%"
                  :error-messages="errors"
                  class="pb-2"
                  dense
                  outlined
                  hide-details="auto"
                  v-model="form.AccommodationHotel"
                  :label="
                    $t('SUPERADMIN.COMMISSION_LABELS.ACCOMMODATION_HOTEL')
                  "
                ></v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                :name="
                  $t(
                    'SUPERADMIN.COMMISSION_LABELS.ACCOMMODATION_SHARED_APARTMENT'
                  )
                "
                rules="max_value_percent:100|required"
              >
                <v-text-field
                  v-mask="'###.##'"
                  suffix="%"
                  :error-messages="errors"
                  class="pb-2"
                  dense
                  outlined
                  hide-details="auto"
                  v-model="form.AccommodationSharedApartment"
                  :label="
                    $t(
                      'SUPERADMIN.COMMISSION_LABELS.ACCOMMODATION_SHARED_APARTMENT'
                    )
                  "
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
        </validation-observer>
      </v-tab-item>
      <v-tab-item eager>
        <validation-observer ref="observerService">
          <v-row>
            <v-col cols="12" xl="6" lg="6" md="6" sm="12">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('SUPERADMIN.COMMISSION_LABELS.SERVICE_TRANSFER')"
                rules="max_value_percent:100|required"
              >
                <v-text-field
                  v-mask="'###.##'"
                  suffix="%"
                  :error-messages="errors"
                  class="pb-2"
                  dense
                  outlined
                  hide-details="auto"
                  v-model="form.ServiceTransfer"
                  :label="$t('SUPERADMIN.COMMISSION_LABELS.SERVICE_TRANSFER')"
                ></v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                :name="$t('SUPERADMIN.COMMISSION_LABELS.SERVICE_ACTIVITIES')"
                rules="max_value_percent:100|required"
              >
                <v-text-field
                  v-mask="'###.##'"
                  suffix="%"
                  :error-messages="errors"
                  class="pb-2"
                  dense
                  outlined
                  hide-details="auto"
                  v-model="form.ServiceActivities"
                  :label="$t('SUPERADMIN.COMMISSION_LABELS.SERVICE_ACTIVITIES')"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="6" sm="12">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('SUPERADMIN.COMMISSION_LABELS.SERVICE_EXAM')"
                rules="max_value_percent:100|required"
              >
                <v-text-field
                  v-mask="'###.##'"
                  suffix="%"
                  :error-messages="errors"
                  class="pb-2"
                  dense
                  outlined
                  hide-details="auto"
                  v-model="form.ServiceExam"
                  :label="$t('SUPERADMIN.COMMISSION_LABELS.SERVICE_EXAM')"
                ></v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                :name="$t('SUPERADMIN.COMMISSION_LABELS.SERVICE_VISA')"
                rules="max_value_percent:100|required"
              >
                <v-text-field
                  v-mask="'###.##'"
                  suffix="%"
                  :error-messages="errors"
                  class="pb-2"
                  dense
                  outlined
                  hide-details="auto"
                  v-model="form.ServiceVisa"
                  :label="$t('SUPERADMIN.COMMISSION_LABELS.SERVICE_VISA')"
                ></v-text-field>
              </validation-provider> </v-col
          ></v-row>
        </validation-observer>
      </v-tab-item>
      <v-tab-item eager>
        <validation-observer ref="observerFee">
          <v-row>
            <v-col cols="12" xl="6" lg="6" md="6" sm="12">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('SUPERADMIN.COMMISSION_LABELS.FEE_REGISTRATION')"
                rules="max_value_percent:100|required"
              >
                <v-text-field
                  v-mask="'###.##'"
                  suffix="%"
                  :error-messages="errors"
                  class="pb-2"
                  dense
                  outlined
                  hide-details="auto"
                  v-model="form.FeeRegistration"
                  :label="$t('SUPERADMIN.COMMISSION_LABELS.FEE_REGISTRATION')"
                ></v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                :name="$t('SUPERADMIN.COMMISSION_LABELS.FEE_COURSE_MATERIAL')"
                rules="max_value_percent:100|required"
              >
                <v-text-field
                  v-mask="'###.##'"
                  suffix="%"
                  :error-messages="errors"
                  class="pb-2"
                  dense
                  outlined
                  hide-details="auto"
                  v-model="form.FeeCourseMaterial"
                  :label="
                    $t('SUPERADMIN.COMMISSION_LABELS.FEE_COURSE_MATERIAL')
                  "
                ></v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                :name="$t('SUPERADMIN.COMMISSION_LABELS.FEE_OTHER')"
                rules="max_value_percent:100|required"
              >
                <v-text-field
                  v-mask="'###.##'"
                  suffix="%"
                  :error-messages="errors"
                  class="pb-2"
                  dense
                  outlined
                  hide-details="auto"
                  v-model="form.FeeOthers"
                  :label="$t('SUPERADMIN.COMMISSION_LABELS.FEE_OTHER')"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="6" sm="12">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('SUPERADMIN.COMMISSION_LABELS.FEE_ACCOMMODATION')"
                rules="max_value_percent:100|required"
              >
                <v-text-field
                  v-mask="'###.##'"
                  suffix="%"
                  :error-messages="errors"
                  class="pb-2"
                  dense
                  outlined
                  hide-details="auto"
                  v-model="form.FeeAccommodation"
                  :label="$t('SUPERADMIN.COMMISSION_LABELS.FEE_ACCOMMODATION')"
                ></v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                :name="$t('SUPERADMIN.COMMISSION_LABELS.FEE_MINOR')"
                rules="max_value_percent:100|required"
              >
                <v-text-field
                  v-mask="'###.##'"
                  suffix="%"
                  :error-messages="errors"
                  class="pb-2"
                  dense
                  outlined
                  hide-details="auto"
                  v-model="form.FeeMinor"
                  :label="$t('SUPERADMIN.COMMISSION_LABELS.FEE_MINOR')"
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
        </validation-observer>
      </v-tab-item>
    </v-tabs-items>

    <v-row v-if="isShowSave">
      <v-col cols="12">
        <v-btn
          depressed
          color="primary"
          class="float-right d-inline-block"
          @click="saveCommission()"
          >{{ $t("GENERAL.ACCEPT") }}</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions } from "vuex";
import Validator from "@/view/content/components/Validator";

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    Validator,
  },
  props: {
    dataForm: {
      type: Object,
      default: function() {
        return {};
      },
    },
    isShowSave: {
      type: Boolean,
      default: false,
    },
    companyId: {
      type: Number,
      default: 0,
    },
    callbackAccept: {
      type: Function,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {
      errorCourse: false,
      errorAccommodation: false,
      errorService: false,
      errorFee: false,
      tab: 0,
      form: {
        CourseBusiness: null,
        CourseExamPreparation: null,
        CourseGeneral: null,
        CourseSeniorPrograms: null,
        CourseTeacherTraining: null,
        CourseWorkAndStudy: null,
        CourseSpecializedLanguage: null,
        CourseJuniors: null,
        CourseUniversityPreparation: null,
        CourseOneToOne: null,
        CourseWithActivities: null,
        CourseHomeTuition: null,
        CourseHomeTuitionJuniors: null,
        AccommodationHomestay: null,
        AccommodationResidence: null,
        AccommodationFullApartment: null,
        AccommodationSharedApartment: null,
        AccommodationHotel: null,
        ServiceTransfer: null,
        ServiceActivities: null,
        ServiceExam: null,
        ServiceVisa: null,
        FeeRegistration: null,
        FeeAccommodation: null,
        FeeCourseMaterial: null,
        FeeMinor: null,
        FeeOthers: null,
      },
      TAB_COURSE: 0,
      TAB_ACCOMMODATION: 1,
      TAB_SERVICE: 2,
      TAB_FEE: 3,
    };
  },
  mounted() {
    if (Object.keys(this.dataForm).length) {
      this.form = this.dataForm;
    }
  },
  methods: {
    ...mapActions(["storeCommission"]),
    async validateForm() {
      const objectKeysForm = Object.keys(this.form);
      this.errorCourse = false;
      this.errorAccommodation = false;
      this.errorService = false;
      this.errorFee = false;
      for (let counter = 0; counter < objectKeysForm.length; counter++) {
        if (!this.form[objectKeysForm[counter]]) {
          const titleInError = objectKeysForm[counter].split(/(?=[A-Z])/);
          this[`error${titleInError[0]}`] = true;
        }
      }
      await this.validateTabWithError();
      return (
        this.errorCourse ||
        this.errorAccommodation ||
        this.errorService ||
        this.errorFee
      );
    },
    async validateTabWithError() {
      switch (this.tab) {
        case this.TAB_COURSE:
          this.errorCourse && (await this.$refs.observerCourse.validate());
          break;
        case this.TAB_ACCOMMODATION:
          this.errorAccommodation &&
            (await this.$refs.observerAccommodation.validate());
          break;
        case this.TAB_SERVICE:
          this.errorService && (await this.$refs.observerService.validate());
          break;
        case this.TAB_FEE:
          this.errorFee && (await this.$refs.observerFee.validate());
          break;
      }
    },
    async saveCommission() {
      const withErrors = await this.validateForm();
      if (!withErrors && this.companyId > 0) {
        const formRequest = {
          ...this.form,
          companyId: this.companyId,
        };
        this.storeCommission(formRequest).then(() => {
          this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
            title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
            variant: "success",
            solid: true,
          });
          this.callbackAccept();
        });
      } else {
        this.$bvToast.toast(this.$t("MESSAGE.ERROR.DESCRIPTION_NEW"), {
          title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
          variant: "danger",
          solid: true,
        });
      }
    },
  },
  watch: {
    form: {
      handler: function(newData) {
        this.$emit("update:input", newData);
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.font-error {
  color: var(--v-error-base) !important;
  caret-color: var(--v-error-base) !important;
}
</style>
