<template>
  <v-card>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('SUPERADMIN.SEARCH')"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="filteredCompanies"
      :search="search"
      multi-sort
    >
      <template v-slot:[`item.company_legal_type`]="{ item }">
        {{ item.bussinessPrivate === 0 ? "Legal" : "Private" }}
      </template>
      <template v-slot:[`item.ACTIONS`]="{ item }">
        <v-tooltip bottom v-if="item.status === 1">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              color="red"
              dark
              v-bind="attrs"
              v-on="on"
              @click="
                showDialogConfirm('CONFIRM_DISABLE', 'DISABLE', item.companyId)
              "
            >
              mdi-close-circle-outline
            </v-icon>
          </template>
          <span>{{ $t("SUPERADMIN.DISABLE") }}</span>
        </v-tooltip>
        <v-tooltip bottom v-if="parseInt(item.status) === 0">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              @click="
                showDialogConfirm('CONFIRM_ENABLE', 'REACTIVE', item.companyId)
              "
            >
              mdi-checkbox-marked-circle-outline
            </v-icon>
          </template>
          <span>{{ $t("SUPERADMIN.ENABLE") }}</span>
        </v-tooltip>
        <!--v-if="item.status === 1"-->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              @click="
                showDialogConfirm('CONFIRM_LOGING', 'LOGING', item.companyId)
              "
            >
              mdi-login
            </v-icon>
          </template>
          <span>{{ $t("SUPERADMIN.LOGING") }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              :color="item.commission_count ? 'green' : 'primary'"
              dark
              v-bind="attrs"
              v-on="on"
              @click="showCommissionDialog(item.companyId)"
            >
              mdi-finance
            </v-icon>
          </template>
          <span>{{ $t("SUPERADMIN.COMISSION") }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              @click="
                showDialogConfirm(
                  'CONFIRM_SEND_EMAIL',
                  'SEND_EMAIL',
                  item.companyId
                )
              "
            >
              mdi-email-outline
            </v-icon>
          </template>
          <span>{{ $t("SUPERADMIN.SEND_EMAIL") }}</span>
        </v-tooltip>
        <!--<v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <font-awesome-icon
              icon="exchange-alt"
              class="mr-2"
              color="#5867dd"
              v-bind="attrs"
              v-on="on"
              @click="
                showDialogConfirm(
                  'CONFIRM_CHANGE_ORIGIN_PRICE',
                  'CHANGE_ORIGIN_PRICE',
                  item.companyId
                )
              "
            ></font-awesome-icon>
          </template>
          <span>{{
            $t("SUPERADMIN.CHANGE_ORIGIN_PRICE", {
              ACTUAL:
                item.origin_price === 0
                  ? $t("SUPERADMIN.CHANGE_ORIGIN_PRICE_API")
                  : $t("SUPERADMIN.CHANGE_ORIGIN_PRICE_EXTERNAL_API"),
              NEW:
                item.origin_price === 0
                  ? $t("SUPERADMIN.CHANGE_ORIGIN_PRICE_EXTERNAL_API")
                  : $t("SUPERADMIN.CHANGE_ORIGIN_PRICE_API"),
            })
          }}</span>
        </v-tooltip>-->
      </template>
    </v-data-table>
    <v-dialog v-model="isShowDialogConfirm" max-width="350">
      <v-card>
        <v-card-title class="headline">
          {{ titleConfirmation }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="isShowDialogConfirm = false">
            {{ $t("MESSAGE.DELETE.MODAL_NO") }}
          </v-btn>

          <v-btn
            color="primary darken-1"
            text
            @click="
              confirm();
              isShowDialogConfirm = false;
            "
          >
            {{ $t("MESSAGE.DELETE.MODAL_YES") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isShowDialogComissions" max-width="750" eager>
      <v-card>
        <v-card-title class="headline">
          {{ $t("SUPERADMIN.COMISSION") }}
        </v-card-title>

        <v-card-text>
          <TabsCommission
            ref="tabsCommission"
            :key="keyTabsCommission"
            :input.sync="formCommission"
            :dataForm="formCommission"
          ></TabsCommission>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="grey"
            class="float-right d-inline-block"
            @click="hideCommissionDialog()"
            >{{ $t("GENERAL.CLOSE") }}</v-btn
          >
          <v-btn
            depressed
            color="primary"
            class="float-right d-inline-block"
            @click="saveCommission()"
            >{{ $t("GENERAL.SAVE") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TabsCommission from "@/view/pages/superadmin/company/components/TabsCommission";
export default {
  components: { TabsCommission },
  props: {
    status: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      titleConfirmation: null,
      search: "",
      headers: [
        {
          text: this.$i18n.t("SUPERADMIN.COMPANY_NAME"),
          value: "commercial_name",
        },
        {
          text: this.$i18n.t("SUPERADMIN.COMPANY_TYPE"),
          value: "company_type.code",
        },
        {
          text: this.$i18n.t("SUPERADMIN.LEGAL_COMPANY_TYPE"),
          value: "company_legal_type",
        },
        { text: this.$i18n.t("SUPERADMIN.ACTIONS"), value: "ACTIONS" },
      ],
      companies: [],
      methodsActions: [
        "SEND_EMAIL",
        "DISABLE",
        "REACTIVE",
        "LOGING",
        "CHANGE_ORIGIN_PRICE",
      ],
      method: null,
      companyId: null,
      isShowDialogConfirm: false,
      isShowDialogComissions: false,
      PENDING: 2,
      ACTIVE: 1,
      DISABLED: 0,
      formCommission: {
        CourseBusiness: null,
        CourseExamPreparation: null,
        CourseGeneral: null,
        CourseSeniorPrograms: null,
        CourseTeacherTraining: null,
        CourseWorkAndStudy: null,
        CourseSpecializedLanguage: null,
        CourseJuniors: null,
        CourseUniversityPreparation: null,
        CourseOneToOne: null,
        CourseWithActivities: null,
        CourseHomeTuition: null,
        CourseHomeTuitionJuniors: null,
        AccommodationHomestay: null,
        AccommodationResidence: null,
        AccommodationFullApartment: null,
        AccommodationSharedApartment: null,
        AccommodationHotel: null,
        ServiceTransfer: null,
        ServiceActivities: null,
        ServicePack: null,
        FeeRegistration: null,
        FeeAccommodation: null,
        FeeExam: null,
        FeeCourseMaterial: null,
        FeeVisa: null,
        FeeGuardianship: null,
        FeeOthers: null,
      },
      keyTabsCommission: 0,
    };
  },
  async mounted() {
    await this.getCompaniesRequest();
  },
  methods: {
    ...mapActions([
      "getAllCompany",
      "sendWizardEmailCompany",
      "inactiveCompany",
      "reactiveCompany",
      "loginCompany",
      "getCommissionByCompany",
      "storeCommission",
      "updateLeftMenu",
      "changeOriginPrice",
    ]),
    showDialogConfirm(title, method, companyId) {
      this.titleConfirmation = this.$i18n.t(`SUPERADMIN.${title}`);
      this.method = method;
      this.companyId = companyId;
      this.isShowDialogConfirm = true;
    },
    confirm() {
      switch (this.method) {
        case this.methodsActions[0]:
          this.sendWizardEmailCompany(this.companyId).then(() => {
            this.$bvToast.toast(this.$t("MESSAGE.EMAIL.SENDED"), {
              title: this.$t("MESSAGE.EMAIL.SENDED_SUCCESS"),
              variant: "success",
              solid: true,
            });
          });
          break;
        case this.methodsActions[1]:
          this.inactiveCompany(this.companyId).then(() => {
            this.searchAndReplace(0);
          });
          break;
        case this.methodsActions[2]:
          this.reactiveCompany(this.companyId).then(() => {
            this.searchAndReplace(1);
          });
          break;
        case this.methodsActions[3]:
          this.loginCompany(this.companyId);
          break;
        case this.methodsActions[4]:
          this.changeOriginPrice(this.companyId).then(() => {
            this.getCompaniesRequest();
          });
          break;

        default:
          break;
      }
    },
    searchAndReplace(value) {
      const company = this.companies?.filter(item => {
        return item.companyId === this.companyId;
      });

      const index = this.companies.indexOf(company[0]);
      this.companies[index].status = value;
    },
    showCommissionDialog(companyId) {
      this.getCommissionByCompany(companyId).then(response => {
        this.formCommission = response;
        this.companyId = companyId;
        this.keyTabsCommission += 1;
        this.isShowDialogComissions = true;
      });
    },
    hideCommissionDialog() {
      this.keyTabsCommission += 1;
      this.isShowDialogComissions = false;
      this.formCommission = {};
      this.companyId = null;
    },
    async saveCommission() {
      const validation = await this.$refs.tabsCommission.validateForm();
      if (!validation) {
        const formRequest = {
          ...this.formCommission,
          companyId: this.companyId,
        };
        this.storeCommission(formRequest).then(() => {
          this.getCompaniesRequest();
          this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
            title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
            variant: "success",
            solid: true,
          });
          this.hideCommissionDialog();
        });
      } else {
        this.$bvToast.toast(this.$t("MESSAGE.ERROR.DESCRIPTION_NEW"), {
          title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
          variant: "danger",
          solid: true,
        });
      }
    },
    async getCompaniesRequest() {
      this.getAllCompany().then(response => {
        this.companies = response;
      });
    },
  },
  computed: {
    ...mapGetters(["commission"]),
    filteredCompanies: function () {
      switch (this.status) {
        case 0:
          return this.companies?.filter(item => {
            return parseInt(item.status) === parseInt(this.DISABLED);
          });
        case 1:
          return this.companies?.filter(item => {
            return parseInt(item.status) === parseInt(this.ACTIVE);
          });
        case 2:
          return this.companies?.filter(item => {
            return parseInt(item.status) === parseInt(this.PENDING);
          });
        default:
          return [];
      }
    },
  },
};
</script>
