import { render, staticRenderFns } from "./TabsCommission.vue?vue&type=template&id=430692a4&scoped=true&"
import script from "./TabsCommission.vue?vue&type=script&lang=js&"
export * from "./TabsCommission.vue?vue&type=script&lang=js&"
import style0 from "./TabsCommission.vue?vue&type=style&index=0&id=430692a4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "430692a4",
  null
  
)

export default component.exports